import Navbar from "../../components/Navbar";
import ButtonTop from "../../components/ButtonTop";
import Footer from "../../components/Footer";
import CookiesAccept from "../../components/CookiesAccept";

import HomeHero from "./components/HomeHero";
import HomeServices from "./components/HomeServices";
import HomeMap from "./components/HomeMap";
import HomeAbout from "./components/HomeAbout";
import HomeContact from "./components/HomeContact";
import HomeFeed from "./components/HomeFeed";
import HomeWeather from "./components/HomeWeather";
import HomeTides from "./components/HomeTides";
import HomeHandling from "./components/HomeHandling";
import MediaButtons from "src/components/MediaButtons";

function Home() {
  return (
    <div id="home">
      <Navbar></Navbar>
      <ButtonTop></ButtonTop>
      <CookiesAccept></CookiesAccept>
      <MediaButtons></MediaButtons>
      <HomeHero></HomeHero>
      <HomeAbout></HomeAbout>
      <HomeServices></HomeServices>
      <HomeContact></HomeContact>
      <HomeMap></HomeMap>
      <HomeFeed></HomeFeed>
      <HomeWeather></HomeWeather>
      <HomeHandling></HomeHandling>
      <HomeTides></HomeTides>
      <Footer></Footer>
    </div>
  );
}

export default Home;
